import { PropsWithChildren } from 'react';
import './tagline.scss';
import classNames from 'classnames';

type TaglineProps = {
    isBlock?: boolean
    withMargin?: boolean
    withMarginTop?: boolean
}

export default function Tagline(props: PropsWithChildren<TaglineProps>) {
    const classes = classNames(
        'tagline',
        {
            'tagline--block': props.isBlock,
            'tagline--margin': props.withMargin,
            'tagline--margin-top': props.withMarginTop,
        },
    );

    return (
        <p className={classes}>
            {props.children}
        </p>
    );
}
