import { axiosClient } from '../../../ApiConfig';
import { Mission } from '../entities/Mission';
import missionSlug from '../../../shared-kernel/services/missionSlug';

const Missions = {
    findBySubdomain: async () => {
        const slug = missionSlug();
        const response = await axiosClient.get<Mission>(`/missions/${slug}`);

        return response.data;
    },
};

export default Missions;
