import { useContext } from 'react';
import { DiveContext } from '../../contexts/DiveContext';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import Button from '../../../../shared-kernel/components/button/Button';
import Text from '../../../../shared-kernel/components/text/Text';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';

const Example = () => {
    const { dive, nextStep } = useContext(DiveContext);

    const clickHandler = () => {
        nextStep();
    };

    return (
        <Grid>
            <Column>
                <Headline type={'h1'} hasBorder={true}>
                    {dive?.example.title}
                </Headline>
            </Column>

            <Column columns={6}>
                <Text asMarkdown={true}>
                    {dive?.example.text}
                </Text>
                <Button buttonType={'fillup'} onClick={clickHandler}>
                    {dive?.example.buttonText}
                </Button>
            </Column>

            <Column columns={6}>
                {dive?.example.image && <img className={'resized-img'} src={dive?.example.image} alt={''} />}
                {dive?.example.additionalText && <Text asMarkdown={true}>{dive?.example.additionalText}</Text>}
            </Column>
        </Grid>
    );
};

export default Example;
