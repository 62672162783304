import Headline from '../../../../shared-kernel/components/headline/Headline';
import React, { BaseSyntheticEvent, useContext, useEffect, useState } from 'react';
import { DiveContext } from '../../contexts/DiveContext';
import Text from '../../../../shared-kernel/components/text/Text';
import Button from '../../../../shared-kernel/components/button/Button';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import Answers from '../../repositories/Answers';
import Dialog, { ButtonBar } from '../../../../shared-kernel/components/dialog/Dialog';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';
import { AppContext } from '../../../../application/contexts/AppContext';
import TextArea from '../../../../shared-kernel/components/input/TextArea';
import Tooltip from '../../../../shared-kernel/components/tooltip/Tooltip';


type AnswerFormData = {
    text: string;
};

type ValidateErrors = {
    text?: string;
};

const AnswerStorageKey = 'action.answer';

const Action = () => {
    const [startTime, setStartTime] = useState<number>();
    const [diveTime, setDiveTime] = useState(1);
    const { updateShowTimer, updateIsTimerRunning } = useContext(AppContext);
    const { dive, nextStep } = useContext(DiveContext);
    const formMethods = useForm<AnswerFormData>();
    const { User } = useContext(AppContext);
    const { handleSubmit, watch} = formMethods;
    const { t} = useTranslation();
    const [showDialog, setShowDialog] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        updateShowTimer(true);

        return () => {
            updateShowTimer(false);
        };
    }, [updateShowTimer]);

    const answerMutation = useMutation({
        mutationFn: Answers.add,
        onSuccess: () => {
            nextStep();
        },
        onError: (error: any) => {
            for (const [key, message] of Object.entries(error.response.data.errors)) {
                formMethods.setError(key as keyof ValidateErrors, { message: t(message) });
            }
            setIsSubmitted(false);
        },
    });

    const submitHandler = ({ text }: AnswerFormData) => {
        setIsSubmitted(true);
        answerMutation.mutate({
            text,
            diveId: dive!.id,
            diveTime,
        });
        localStorage.removeItem(AnswerStorageKey);
    };

    const showDialogHandler = () => {
        setShowDialog(true);
    };

    const hideDialogHandler = () => {
        setShowDialog(false);
    };

    const skipActionHandler = () => {
        nextStep();
        localStorage.removeItem(AnswerStorageKey);
    };

    const timerHandler = ({ type }: BaseSyntheticEvent) => {
        if ('focus' === type) {
            setStartTime(Date.now());
            updateIsTimerRunning(true);
        } else {
            setDiveTime(diveTime + Math.floor((Date.now() - startTime!) / 1000));
            updateIsTimerRunning(false);
            setStartTime(undefined);
        }
    };

    watch((data) => {
        localStorage.setItem(AnswerStorageKey, data.text ?? '');
    });

    return (
        <>
            <Grid>
                <Column>
                    <Headline type={'h1'} hasBorder={true}>{dive?.action.title}</Headline>
                </Column>
                <Column columns={6}>
                    <Text asMarkdown={true}>{dive?.action.text}</Text>
                    <FormProvider {...formMethods}>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <TextArea
                                name={'text'}
                                placeholder={t('domain.dive.form.field.text')}
                                maxLength={280}
                                defaultValue={localStorage.getItem(AnswerStorageKey) ?? ''}
                                onFocus={timerHandler}
                                onBlur={timerHandler}
                                hasCounter={true}
                            />

                            <Tooltip
                                isDisabled={User!.currentDive !== 0}
                                labelKey="tooltip.label.safe_space"
                                tooltipAlt="tooltip.icon.alt_text"
                                tooltipKey="tooltip.content.safe_space"
                            />


                            <Button buttonType={'secondary'} type={'button'} onClick={showDialogHandler} >
                                {t('button.skip')}
                            </Button>
                            <Button buttonType={'fillup'} type={'submit'} isSubmitted={isSubmitted}>
                                {t('button.submit')}
                            </Button>
                        </form>
                    </FormProvider>
                </Column>
            </Grid>

            <Dialog showDialog={showDialog}>
                <Headline type={'h2'} hasMarginTop={true}>
                    {t('domain.dive.action-skip-dialog.title')}
                </Headline>

                <ButtonBar>
                    <Button buttonType={'secondary'} onClick={hideDialogHandler}>
                        {t('button.back')}
                    </Button>

                    <Button buttonType={'fillup'} onClick={skipActionHandler}>
                        {t('domain.dive.action-skip-dialog.button')}
                    </Button>
                </ButtonBar>
            </Dialog>
        </>
    );
};

export default Action;
