import './more-answer-card.scss';
import { Answer } from '../../../dive/entities/Answer';
import { useState } from 'react';
import Card from '../../../../shared-kernel/components/card/Card';
import { useTranslation } from 'react-i18next';
import Text from '../../../../shared-kernel/components/text/Text';
import Tagline from '../../../../shared-kernel/components/tagline/Tagline';
import classNames from 'classnames';
import Headline from '../../../../shared-kernel/components/headline/Headline';

type MoreAnswerCardProps = {
    answers: Answer[]
    isHighlighted: boolean
}

const MoreAnswerCard = (props: MoreAnswerCardProps) => {
    const [answerIndex, setAnswerIndex] = useState(0);
    const { t } = useTranslation();

    const cardClasses = classNames('more-answer-card', {
        'more-answer-card__highlighted': props.isHighlighted,
    });

    const moveBackHandler = () => {
        let newAnswerIndex = answerIndex - 1;

        if (newAnswerIndex < 0) {
            newAnswerIndex = props.answers.length - 1;
        }

        setAnswerIndex(newAnswerIndex);
    };

    const moveForwardHandler = () => {
        let newAnswerIndex = answerIndex + 1;

        if (newAnswerIndex >= props.answers.length) {
            newAnswerIndex = 0;
        }

        setAnswerIndex(newAnswerIndex);
    };

    return (
        <Card className={cardClasses}>
            <div className={'card__header'}>
                <div>
                    <div className={'more-answer-card__pages'}>
                        {answerIndex + 1}/{props.answers.length}
                    </div>
                    <Headline type={'h4'}>{t('domain.explore.moreAnswers.title')}</Headline>
                </div>

                <div className={'more-answer-card__icon-wrapper'}>
                    <div className={'more-answer-card__icon more-answer-card__icon--back'} onClick={moveBackHandler}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40Z" className={'more-answer-card__icon--background'} />
                            <path d="M23 12L15 20L23 28" strokeWidth="1.5" className={'more-answer-card__icon--arrow'} />
                        </svg>
                    </div>
                    <div className={'more-answer-card__icon more-answer-card__icon--forward'} onClick={moveForwardHandler}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40Z" className={'more-answer-card__icon--background'} />
                            <path d="M17 12L25 20L17 28" strokeWidth="1.5" className={'more-answer-card__icon--arrow'}/>
                        </svg>
                    </div>
                </div>
            </div>

            <div>
                <div className={'card__body-text'}>
                    <Text>{props.answers[answerIndex].text}</Text>
                </div>
                    {(props.answers[answerIndex].autor || props.answers[answerIndex].isAutoTranslated) &&
                        (<div className="card__info">
                            {props.answers[answerIndex].autor && <Tagline>{props.answers[answerIndex].autor}</Tagline>}
                            {props.answers[answerIndex].isAutoTranslated && (
                                <div className="card__translation-indicator">
                                    {t('text.translated_by_ai')}
                                </div>)}
                        </div>)
                    }
            </div>
        </Card>
    );
};

export default MoreAnswerCard;
