import './tooltip.scss';
import { useRef } from 'react';
import TooltipIcon from '../../assets/icons/Tooltip-ICN.svg';
import { useTranslation } from 'react-i18next';

interface TooltipProps {
    labelKey: string;
    tooltipKey: string;
    tooltipAlt: string;
    isDisabled?: boolean;
}

const Tooltip = ({ labelKey, tooltipKey, tooltipAlt, isDisabled = false }: TooltipProps) => {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const { t } = useTranslation();

    if (isDisabled) {
        return null;
    }

    const handleMouseEnter = () => {
        dialogRef.current?.show()
    }

    const handleMouseLeave = () => {
        dialogRef.current?.close();
    }

    return (
        <div className="tooltip-container"
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            <img src={TooltipIcon} alt={t(tooltipAlt)}/>
            <span className="tooltip-label">{t(labelKey)}</span>
            <dialog ref={dialogRef} className="tooltip-box">{t(tooltipKey)}</dialog>
        </div>
    );
};

export default Tooltip;
