import React, { useContext } from 'react';
import Text from '../../../shared-kernel/components/text/Text';
import { useTranslation } from 'react-i18next';
import NotFoundImage from './../../assets/images/404.svg';
import Headline from '../../../shared-kernel/components/headline/Headline';
import Button from '../../../shared-kernel/components/button/Button';
import { useNavigate } from 'react-router-dom';
import Grid from '../../../shared-kernel/components/grid/Grid';
import Column from '../../../shared-kernel/components/grid/Column';
import { AppContext } from '../../contexts/AppContext';

const Error = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { User } = useContext(AppContext);

    return (
        <div className={'m-intro'}>
            <Grid>
                <Column columns={11} offset={3}>
                    <Grid>
                        <Column columns={6}>
                            <img src={NotFoundImage} className={'resized-img'} alt={''} />
                        </Column>
                        <Column columns={6}>
                            <Headline type={'h1'}>{t('page.error.headline')}</Headline>
                            {User && <Button buttonType={'primary'} onClick={() => navigate('/')}>{t('page.error.button')}</Button>}
                            <Text>{t('page.error.copy')}</Text>
                        </Column>
                    </Grid>
                </Column>
            </Grid>
        </div>
    );
};

export default Error;
