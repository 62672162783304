import { Answer } from '../../../dive/entities/Answer';
import Loading from '../../../../application/pages/loading/Loading';
import WaitingRoom from '../waiting-room/WaitingRoom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../../application/contexts/AppContext';
import Explores from '../../repositories/Explores';
import Button from '../../../../shared-kernel/components/button/Button';
import { useTranslation } from 'react-i18next';
import ContinueDive from '../continue-dive/ContinueDive';
import Navigation from '../../../dive/components/navigation/Navigation';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import ExcellentAnswerCard from '../../components/excellent-answer-card/ExcellentAnswerCard';
import FeedbackCard from '../../components/feedback-card/FeedbackCard';
import MoreAnswerCard from '../../components/more-answer-card/MoreAnswerCard';
import OwnAnswerCard from '../../components/own-answer-card/OwnAnswerCard';
import StatisticCard from '../../components/statistic-card/StatisticCard';

function getObjectKey(obj: any, value: any): any {
    return Object.keys(obj).find((key: any) => obj[key].id === value);
}

const ExploreDive = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { User, dives } = useContext(AppContext);
    const { data: explore, isLoading } = useQuery({
        queryKey: ['explore-answers', id],
        queryFn: () => Explores.findByDiveId(parseInt(id!)),
    });

    if (User!.currentDive === undefined) {
        navigate('/');
    }

    const dive = dives.find((dive) => dive.id === parseInt(id!));
    const diveIndex = parseInt(getObjectKey(dives, parseInt(id!)));
    const isLastExplore = User!.currentDive >= dives.length && diveIndex === dives.length - 1;
    const isDiveDone = User!.currentDive >= dives.length;

    if (isLoading) {
        return <Loading />;
    }

    if (explore!.answers.length <= 5) {
        return <WaitingRoom />;
    }

    if (diveIndex + 1 > User!.currentDive) {
        return <ContinueDive />;
    }

    let button = null;
    if (isDiveDone) {
        if (!isLastExplore) {
            button =
                <Button buttonType={'primary'} noMargin={true} onClick={() => navigate(`/explore/${dives[diveIndex + 1].id}`)}>
                    {t('domain.explore.next-dive-explore.button')}
                </Button>

        }
    } else {
        button =
            <Button buttonType={'primary'} noMargin={true} onClick={() => navigate('/dive/scenario')}>
                {t('domain.explore.dive-continue.button')}
            </Button>
    }

    return (
        <>
            <Navigation />

            <Headline type={'h1'} hasBorder={false}>
                {dive?.exploreTitle}
            </Headline>

            <div className={'card__column-wrapper'}>
                <div className={'card__column'}>
                    {explore!.excellentAnswers.slice(0, 3).map((answer: Answer) => (
                        <ExcellentAnswerCard key={answer.id} answer={answer} />
                    ))}

                    <MoreAnswerCard answers={explore!.answers} isHighlighted={!isLastExplore} />

                    {button}

                </div>

                <div className={'card__column'}>
                    <StatisticCard
                        totalDiveTime={explore!.totalDiveTime}
                        ownDiveTime={explore!.ownDiveTime}
                        totalTypedChars={explore!.totalTypedChars}
                        totalAnswerCount={explore!.totalAnswerCount}
                    />

                    {explore!.ownAnswer! && <OwnAnswerCard answer={explore!.ownAnswer} />}

                    {explore!.excellentAnswers[3] && (
                        <ExcellentAnswerCard key={explore!.excellentAnswers[3].id} answer={explore!.excellentAnswers[3]} />
                    )}

                    {isLastExplore && <FeedbackCard />}

                </div>
            </div>


        </>
    );
};

export default ExploreDive;
