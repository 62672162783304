import { RouteObject } from 'react-router-dom';
import Root from './Root';
import Welcome from './pages/welcome/Welcome';
import RegistrationForm from './pages/registration-form/RegistrationForm';
import LanguageSelection from './pages/language-selection/LanguageSelection';
import ParticipantLink from './pages/participant-link/ParticipantLink';

const RegistrationRoutes: RouteObject = {
    path: 'registration',
    element: <Root />,
    children: [
        {
            path: 'welcome',
            element: <Welcome />,
        },
        {
            path: 'language-selection',
            element: <LanguageSelection />,
        },
        {
            path: 'create-account',
            element: <RegistrationForm />,
        },
        {
            path: 'participant-link',
            element: <ParticipantLink />,
        },
    ],
};

export default RegistrationRoutes;
