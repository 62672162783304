import IconCorrect from '../../../../shared-kernel/assets/icons/ICO_Correct.svg';
import './vote-card.scss';
import { Answer } from '../../entities/Answer';
import Card from '../../../../shared-kernel/components/card/Card';
import classNames from 'classnames';
import Button from '../../../../shared-kernel/components/button/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

type VoteCardProps = {
    answer: Answer
    isSelected: boolean
    onSelect: (answer: Answer) => void
    onVote: () => void
}

const VoteCard = (props: VoteCardProps) => {
    const { t } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const badgeClasses = classNames('vote-card__badge', { 'vote-card--selected': props.isSelected });
    const classes = classNames({ 'vote-card--selected': props.isSelected });

    const voteHandler = () => {
        props.onSelect(props.answer);
    };

    const submitHandler = () => {
        setIsSubmitted(true);
        props.onVote();
    };

    return (
        <Card className={classes}>
            <p className={'card__body-text'}>
                {props.answer.text}
            </p>

            <div className={'card__button-wrapper'}>
                <div className={badgeClasses} onClick={voteHandler}>
                    <img className={'vote-card__badge-icon'}
                         src={IconCorrect}
                         alt='correct thumb'
                    />
                </div>

                {props.isSelected &&
                    <Button buttonType={'secondary'} onClick={submitHandler} isSubmitted={isSubmitted}>
                        {t('button.submit')}
                    </Button>
                }

                {props.answer.isAutoTranslated && !props.isSelected &&(
                    <div className="card__translation-indicator">
                        {t('text.translated_by_ai')}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default VoteCard;
